import React, {Component, Fragment} from 'react';
import {Col, Container, Row} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEnvelope, faPhone, faPlayCircle} from "@fortawesome/free-solid-svg-icons";

import {faFacebook, faYoutube } from "@fortawesome/free-brands-svg-icons";
import {Link} from "react-router-dom";
import RestClient from "../../RestAPI/RestClient";
import AppUrl from "../../RestAPI/AppUrl";


class Footer extends Component {

    constructor() {
        super();
        this.state={
            address:"",
            email:"",
            phone:"",
            facebook:"",
            youtube:"",
            footer_credit:""
        }
    }

    componentDidMount() {
        RestClient.GetRequest(AppUrl.footer).then(result=>{
            this.setState({address:result[0]['address'],
                email:result[0]['email'],
                phone:result[0]['phone'],
                facebook:result[0]['facebook'],
                youtube:result[0]['youtube'],
                footer_credit:result[0]['footer_credit'],
            });
        })
    }



    render() {
        return (
            <Fragment>
                <Container fluid={true} className=" text-center footerSection">
                    <Row>
                        {/*<Col lg={6} md={6} sm={12} className="p-5 text-justify">*/}
                        {/*    <h1 className="serviceName">Social Network</h1>*/}
                        {/*    <a className="socialLink" href="#"> <FontAwesomeIcon icon={faFacebook} />Facebook</a> <br />*/}
                        {/*    <a className="socialLink" href="#"> <FontAwesomeIcon icon={faYoutube} />Facebook</a> <br />*/}

                        {/*</Col>*/}

                        {/*<Col lg={3} md={6} sm={12} className="p-5 text-justify">*/}
                        {/*    <h1 className="serviceName">Address</h1>*/}
                        {/*    <p>{this.state.address}</p>*/}
                        {/*    <p>{this.state.email}</p>*/}
                        {/*    <p>{this.state.phone} </p>*/}
                        {/*</Col>*/}

                        {/*<Col lg={3} md={6} sm={12} className="p-5 text-justify">*/}
                        {/*    <h1 className="serviceName">Information</h1>*/}
                        {/*    <Link className="footerLink" to="/about">About Us</Link><br />*/}
                        {/*    <Link className="footerLink" to="/about">Company CSR</Link><br />*/}
                        {/*    <Link className="footerLink" to="/contact">Support</Link><br />*/}
                        {/*    <Link className="footerLink" to="/contact">Contact</Link><br />*/}
                        {/*</Col>*/}

                        <Col lg={12} md={12} sm={12} className="p-5 text-center">
                            <h1 className="serviceName">Company Information</h1>
                            <p>CPO Experts is a registered company in Bangladesh.</p>
                            <p>Registration No: 174, TIN:116004135658.</p>
                            <p>Do you have a question? </p>
                            <p>Contact Us:</p>
                            <p><a className="linkStyle" href={"https://www.clippingpathoutsource.com"}>Clipping Path Outsource</a></p>
                            <p><a className="linkStyle" href={"https://www.clippingpathoutsource.com"}>We Dev Solution</a></p>

                            {/*<Link className="footerLink" to="/about">About CPO</Link><br />*/}
                            {/*<Link className="footerLink" to="/about">About WeDevSolution</Link><br />*/}
                            {/*<Link className="footerLink" to="/about">Company Blog</Link><br />*/}
                            {/*<Link className="footerLink" to="/about">Company CSR</Link><br />*/}
                            {/*<Link className="footerLink" to="/refund">Refund Policy</Link><br />*/}
                            {/*<Link className="footerLink" to="/termsofuse">Terms of Use</Link><br />*/}
                            {/*<Link className="footerLink" to="/privacy">Privacy Policy</Link><br />*/}
                        </Col>
                    </Row>
                </Container>


                <Container fluid={true} className="copyRightSection text-center">
                    <p className="copyRightLink">{this.state.footer_credit}</p>
                    {/*<a className="copyRightLink" href="#">{this.state.footer_credit}</a>*/}
                </Container>
            </Fragment>
        );
    }
}

export default Footer;