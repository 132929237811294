import React, {Component, Fragment} from 'react';
import {Button, Col, Container, Modal, Row} from "react-bootstrap";
import {BigPlayButton, Player} from "video-react";

class CourseDetails extends Component {
    render() {
        return (
            <Fragment>
                <Fragment>
                    <Container fluid={true} className="topFixedPage p-0" >
                        <div className="topPageOverlay">
                            <Container className="topContentCourse">
                                <Row>
                                    <Col sm={12} md={6} lg={6}>
                                        <h3 className="courseFullTitle">Dynami website adfladj</h3>
                                        <h5 className="courseSubTitle"> Total lecture=20 </h5>
                                        <h5 className="courseSubTitle"> Total student=20 </h5>
                                    </Col>

                                    <Col sm={12} md={6} lg={6}>
                                        <p className="courseDes">  Visitors of Clipping Path Outsource are requested to look our newly privacy policy in order to cope up with our updated policies although ClippingPathOutsource.com will announce if such updates are made.
                                        </p>
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                    </Container>

                    <Container className="mt-5">
                        <Row>
                            <Col sm={12} md={6} lg={6}>
                                <h1 className="serviceName">Skills you get</h1>

                                <ul>
                                    <li className="serviceDescription">ajdflja lfjad adsfoasdfj</li>
                                    <li className="serviceDescription">ajdflja lfjad adsfoasdfj</li>
                                    <li className="serviceDescription">ajdflja lfjad adsfoasdfj</li>
                                    <li className="serviceDescription">ajdflja lfjad adsfoasdfj</li>
                                    <li className="serviceDescription">ajdflja lfjad adsfoasdfj</li>
                                    <li className="serviceDescription">ajdflja lfjad adsfoasdfj</li>
                                </ul>
                                <Button variant="primary">Get in Touch</Button>
                            </Col>

                            <Col sm={12} md={6} lg={6}>

                                <Player>
                                    <source src="https://media.w3.org/2010/05/sintel/trailer_hd.mp4" />
                                    <BigPlayButton position="center"/>
                                </Player>

                            </Col>
                        </Row>

                    </Container>
                </Fragment>
            </Fragment>
        );
    }
}

export default CourseDetails;