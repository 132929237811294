import React, {Component, Fragment} from 'react';
import {Nav, Navbar, NavDropdown} from "react-bootstrap";
import navLogo from '../../asset/image/cpo-experts-logo.svg';
import navBarScrollLogo from '../../asset/image/cpo-experts-logo.svg';
import '../../asset/css/custom.css'
import '../../asset/css/bootstrap.min.css'
import {Link, NavLink} from "react-router-dom";

class TopNavigation extends Component {

    constructor(props){
        super();
        this.state={
            navBarTitle:"navTitle",
            navBarLogo: [navBarScrollLogo],
            navBarItem: "navItem",
            pageTitle: props.title,
        }
    }


    onScroll=()=>{
        if(window.scrollY>100){
            this.setState({navBarTitle: 'navTitleScroll', navBarLogo: [navLogo]})
        }
        else if (window.scrollY<100){
            this.setState( {navBarTitle: 'navTitle', navBarLogo: [navBarScrollLogo]})
        }
    }

    componentDidMount() {
        window.addEventListener('scroll', this.onScroll)
    }

    render() {
        return (
            <Fragment>
                <title>{this.state.pageTitle}</title>

                <Navbar fixed="top" collapseOnSelect expand="lg" bg="dark" variant="dark">
                    <Navbar.Brand ><NavLink className={this.state.navBarTitle} to="/"> <img src={this.state.navBarLogo}/> CPO Experts </NavLink> </Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="mr-auto">
                        </Nav>
                        <Nav>
                            <Nav.Link><Link exact activeStyle={{color:'#65BDD9'}} className={this.state.navBarItem} to="/">HOME</Link></Nav.Link>
                            <Nav.Link><Link exact activeStyle={{color:'#65BDD9'}} className={this.state.navBarItem} to="/service">SERVICES</Link> </Nav.Link>
                            {/*<NavDropdown title="Services" id="collasible-nav-dropdown">*/}
                            {/*    <NavDropdown.Item to="#action/3.1">Action</NavDropdown.Item>*/}
                            {/*    <NavDropdown.Item to="#action/3.2">Another action</NavDropdown.Item>*/}
                            {/*    <NavDropdown.Item to="#action/3.3">Something</NavDropdown.Item>*/}
                            {/*    <NavDropdown.Divider />*/}
                            {/*    <NavDropdown.Item to="#action/3.4">Separated link</NavDropdown.Item>*/}
                            {/*</NavDropdown>*/}

                            {/*<Nav.Link><NavLink exact activeStyle={{color:'#65BDD9'}} className={this.state.navBarItem} to="/course">Courses</NavLink> </Nav.Link>*/}
                            {/*<Nav.Link><NavLink exact activeStyle={{color:'#65BDD9'}} className={this.state.navBarItem} to="/portfolio">Portfolio</NavLink> </Nav.Link>*/}
                            {/*<Nav.Link><NavLink exact activeStyle={{color:'#65BDD9'}} className={this.state.navBarItem} to="/portfolio">Portfolio</NavLink> </Nav.Link>*/}
                            {/*<Nav.Link><NavLink exact activeStyle={{color:'#65BDD9'}} className={this.state.navBarItem} to="/portfolio">Portfolio</NavLink> </Nav.Link>*/}
                            {/*<Nav.Link><NavLink exact activeStyle={{color:'#65BDD9'}} className={this.state.navBarItem} to="/portfolio">THE</NavLink> </Nav.Link>*/}
                            <Nav.Link><Link exact activeStyle={{color:'#65BDD9'}} className={this.state.navBarItem} to="/team">THE TEAM</Link> </Nav.Link>
                            <Nav.Link><Link exact activeStyle={{color:'#65BDD9'}} className={this.state.navBarItem} to="/csr">CSR</Link> </Nav.Link>
                            {/*<Nav.Link><NavLink exact activeStyle={{color:'#65BDD9'}} className={this.state.navBarItem} to="/about">ABOUT</NavLink> </Nav.Link>*/}
                            <Nav.Link><Link exact activeStyle={{color:'#65BDD9'}} className={this.state.navBarItem} to="/aboutus">ABOUT US</Link> </Nav.Link>
                            <Nav.Link><Link exact activeStyle={{color:'#65BDD9'}} className={this.state.navBarItem} to="/contact">CONTACT US</Link> </Nav.Link>

                            {/*<Nav.Link><NavLink exact activeStyle={{color:'#ff6d19'}} className={this.state.navBarItem} to="contact">Sign In</NavLink> </Nav.Link>*/}


                        </Nav>
                    </Navbar.Collapse>
                </Navbar>

            </Fragment>
        );
    }
}

export default TopNavigation;