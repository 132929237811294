import React, {Component, Fragment} from 'react';
import {Col, Container, Row} from "react-bootstrap";
import RestClient from "../../RestAPI/RestClient";
import AppUrl from "../../RestAPI/AppUrl";
import ReactHtmlParser from "react-html-parser";

class AboutDescription extends Component {

    // constructor() {
    //     super();
    //     this.state={
    //         desc:""
    //     }
    // }
    //
    // componentDidMount() {
    //     RestClient.GetRequest(AppUrl.information).then(result=>{
    //         this.setState({desc:result[0]['about']});
    //     })
    // }

    render() {
        return (
            <Fragment>
                <Container className="mt-5 text-justify">
                    <Row>
                        <Col sm={12} md={12} lg={12}>
                            <h1>Who We Are </h1>
                            <hr />
                            <p> The CPO Experts is an IT company, started serving with photo editing,
                                clipping path and vector conversion services.
                                Now, we have a great team of Programmer, Web Developer, SEO Marketer, Graphic Designer.
                                We are a rapidly growing company in Bangladesh. We are open 24 hours, providing Retouching services.
                                Also, our skilled software developer innovating excellent software that will solve your business
                                challenges to make everything digitalized. We have a friendly workplace with great empathy for our office staff.
                                When passion becomes a profession, then no tiredness so we love what we do every day.
                            </p>
                            <h1> Our Goal </h1>
                            <hr />
                            <p> Our focus is to deliver high-quality image editing services at a competitive rate.
                                Not only providing services for photo editing to the e-commerce website but also giving
                                a complete solution to e-commerce companies with doing all tasks. Our dedicated team will
                                offer you the perfect solution to the digitalization of your business.
                            </p>
                            <h1> Story behind </h1>
                            <hr />
                            <p> CPO Experts initially began its journey as a startup outsourcing company.
                                After gathering tremendous experience in the image processing industry,
                                we launched our company in September 2012 as a small company.
                                We dream to make an IT company, and we are following the steps successfully.
                                Our broader vision helps us to be more dedicated, and experience helps us
                                to be more confident in every challenging situation. One of our challenges
                                was to work in a remote area. We overcome the challenging situation and growing the company rapidly.
                                In the beginning, we trained our graphic designer,
                                and now they are capable of completing thousands of image editing tasks every day.
                                <br />
                                We introduced web application development service in 2016
                                so that we can provide a complete e-commerce solution to individuals or companies.
                                Now We have thousands of clients all over the world, getting our services with
                                a very reasonable cost compare to our competitors.
                            </p>

                            <h1> Our Mission </h1>
                            <hr />
                            <p> CPO Experts comprise of a team of dedicated professional with the right attitude,
                                offering the consistent image editing quality and excellent customer service.
                                All employees are given training and mentorship to improve skills and self-development.
                                We are committed to providing image optimization or IT services.
                                We provide scholarship to the meritorious student to prosper their future.
                                Our mission is to give return back to society and able to thrive locally in Dinajpur, Bangladesh.
                            </p>
                        </Col>
                    </Row>
                </Container>
            </Fragment>
        );
    }
}

export default AboutDescription;