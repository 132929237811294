import React, { Component, Fragment } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import RestClient from "../../RestAPI/RestClient";
import AppUrl from "../../RestAPI/AppUrl";
import ReactHtmlParser from "react-html-parser";

class ContactSection extends Component {
    constructor() {
        super();
        this.state = {
            address: "",
            email: "",
            phone: ""
        };
    }

    componentDidMount() {
        RestClient.GetRequest(AppUrl.footer).then(result => {
            this.setState({
                address: result[0]["address"],
                email: result[0]["email"],
                phone: result[0]["phone"],
            });
        });
    }

    sendContact() {
        let name = document.getElementById("name").value;
        let email = document.getElementById("email").value;
        let msg = document.getElementById("msg").value;

        let jsonObject={name:name,email:email,msg:msg};

        RestClient.PostRequest(AppUrl.ContactSend,JSON.stringify(jsonObject)).then(result=>{
            alert(result);
        }).catch(error=>{
            alert('Error');
        })
    }



    render() {
        return (
            <Fragment>
                <Container className="mt-5">
                    <Row>
                        <Col lg={4} md={6} sm={12}>
                            <h1 className="serviceName">Office Address:</h1>
                            <p>CPO Experts </p>
                            <p>Rahman Tower, Chirirbandar, </p>
                            <p>Dinajpur-5240, Bangladesh</p>
                            <p>Mobile: +88 01734173799</p>
                            <p>Email: info@cpoexpertsbd.com</p>
                        </Col>

                        <Col lg={4} md={6} sm={12}>
                            <h1 className="serviceName">Office Address:</h1>
                            <p>157/C Dr. Goli, Moghbazar, Dhaka-1217 </p>
                            <p> Bangladesh</p>
                            <p>Mobile: +88 01734173799</p>
                            <p>Email: info@cpoexpertsbd.com</p>
                        </Col>

                        <Col lg={4} md={6} sm={12}>
                            <h1 className="serviceName">Office Address:</h1>
                            <p>{this.state.address}</p>
                            <p>{this.state.phone}</p>
                            <p>{this.state.email}</p>
                        </Col>
                    </Row>
                </Container>
            </Fragment>
        );
    }
}

export default ContactSection;
