import React, {Component, Fragment} from 'react';
import TopNavigation from "../components/TopNavigation/TopNavigation";
import PageTop from "../components/PageTop/PageTop";
import Footer from "../components/Footer/Footer";
import AboutUsDesc from "../components/AboutUsDesc/AboutUsDesc";
import CorporateSocialResponsibility from "../components/CSR/CorporateSocialResponsibility";

class CSRPage extends Component {

    componentDidMount() {
        window.scroll(0,0)
    }

    render() {
        return (
            <Fragment>
                <TopNavigation title="Corporate Social Responsibility | CPO Experts " />
                <PageTop pagetitle="Corporate Social Responsibility" />
                <CorporateSocialResponsibility />
                <Footer />
            </Fragment>
        );
    }
}

export default CSRPage;