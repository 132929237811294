import React, {Component, Fragment} from 'react';
import TopNavigation from "../components/TopNavigation/TopNavigation";
import PageTop from "../components/PageTop/PageTop";
import Footer from "../components/Footer/Footer";
import AboutUsDesc from "../components/AboutUsDesc/AboutUsDesc";

class AboutUsPage extends Component {

    componentDidMount() {
        window.scroll(0,0)
    }

    render() {
        return (
            <Fragment>
                <TopNavigation title="About Us" />
                <PageTop pagetitle="About CPO Experts BD" />
                <AboutUsDesc />
                <Footer />
            </Fragment>
        );
    }
}

export default AboutUsPage;