import React, {Component, Fragment} from 'react';
import {Button, Card, Col, Container, Row} from "react-bootstrap";
import Nitai from '../../asset/image/Nitai.jpg';
import TestImg from '../../asset/image/test.jpg';
import Chandro from '../../asset/image/chandro.jpg';
import Proshanto from '../../asset/image/proshanto.jpg';
import Pollob from '../../asset/image/pollob.jpg';
import Sokal from '../../asset/image/Sokal.jpg';
import Ratan from '../../asset/image/ratan.jpg';
import Gopal from '../../asset/image/Gopal.jpg';
import RestClient from "../../RestAPI/RestClient";
import AppUrl from "../../RestAPI/AppUrl";
import ReactHtmlParser from "react-html-parser";

class TheTeam extends Component {

    // constructor() {
    //     super();
    //     this.state={
    //         desc:""
    //     }
    // }n
    //
    // componentDidMount() {
    //     RestClient.GetRequest(AppUrl.information).then(result=>{
    //         this.setState({desc:result[0]['about']});
    //     })
    // }

    render() {
        return (
            <Fragment>
                <Container className="mt-5">
                    <Row>
                        <Col sm={12} md={12} lg={4} >
                            <h1 className="teamTitle">The Team of CPO Experts provides Image Editing & IT service.</h1>
                        </Col>
                        <Col sm={12} md={12} lg={4}>
                            <Card  >
                                <Card.Img variant="top" src={Gopal} />
                                <Card.Body>
                                    <Card.Title>Mr. Gopal Roy</Card.Title>
                                    <Card.Text className="teamCard">
                                        Chairman
                                    </Card.Text>
                                </Card.Body>
                            </Card>

                        </Col>
                        <Col sm={12} md={12} lg={4}>
                            <Card  >
                                <Card.Img variant="top" src={Nitai} />
                                <Card.Body>
                                    <Card.Title>Nitai Roy</Card.Title>
                                    <Card.Text className="teamCard">
                                        Founder, CEO
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
                <br />
                <Container>
                    <Row>
                        <Col sm={12} md={6} lg={3}>
                        <Card  >
                            <Card.Img variant="top" src={Proshanto} />
                            <Card.Body>
                                <Card.Title>Proshanta Kumar Roy</Card.Title>
                                <Card.Text className="teamCard">
                                    General Manager
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                        <Col sm={12} md={6} lg={3}>
                            <Card  >
                                <Card.Img variant="top" src={Ratan} />
                                <Card.Body>
                                    <Card.Title>Sudhir Barman</Card.Title>
                                    <Card.Text className="teamCard">
                                        Production Manager
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col sm={12} md={6} lg={3}>
                            <Card  >
                                <Card.Img variant="top" src={TestImg} />
                                <Card.Body>
                                    <Card.Title>Anup Roy</Card.Title>
                                    <Card.Text className="teamCard">
                                        SEO & Software Engineer
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col sm={12} md={6} lg={3}>
                            <Card  >
                                <Card.Img variant="top" src={TestImg} />
                                <Card.Body>
                                    <Card.Title>Paritosh Roy</Card.Title>
                                    <Card.Text className="teamCard">
                                        SEO & Software Engineer
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>

                <br />
                <Container>
                    <Row>
                        <Col sm={12} md={6} lg={3}>
                            <Card  >
                                <Card.Img variant="top" src={TestImg} />
                                <Card.Body>
                                    <Card.Title> Md. Faridul Islam </Card.Title>
                                    <Card.Text className="teamCard">
                                        Project Manager
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col sm={12} md={6} lg={3}>
                            <Card  >
                                <Card.Img variant="top" src={TestImg} />
                                <Card.Body>
                                    <Card.Title>Liton Roy</Card.Title>
                                    <Card.Text className="teamCard">
                                        Project Manager
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col sm={12} md={6} lg={3}>
                            <Card  >
                                <Card.Img variant="top" src={Sokal} />
                                <Card.Body>
                                    <Card.Title>Dherendra Nath Roy</Card.Title>
                                    <Card.Text className="teamCard">
                                        Project Manager
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col sm={12} md={6} lg={3}>
                            <Card  >
                                <Card.Img variant="top" src={TestImg} />
                                <Card.Body>
                                    <Card.Title>Prodip Kumar Roy</Card.Title>
                                    <Card.Text className="teamCard">
                                        Head of Project
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>

                <br />
                <Container>
                    <Row>
                        <Col sm={12} md={6} lg={3}>
                            <Card  >
                                <Card.Img variant="top" src={Chandro} />
                                <Card.Body>
                                    <Card.Title>Chondro Shikor Roy</Card.Title>
                                    <Card.Text className="teamCard">
                                        Head of Support
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col sm={12} md={6} lg={3}>
                            <Card  >
                                <Card.Img variant="top" src={TestImg} />
                                <Card.Body>
                                    <Card.Title>Dipok Roy (Dipu)</Card.Title>
                                    <Card.Text className="teamCard">
                                        Support Engineer
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col sm={12} md={6} lg={3}>
                            <Card  >
                                <Card.Img variant="top" src={TestImg} />
                                <Card.Body>
                                    <Card.Title>Biplob Roy</Card.Title>
                                    <Card.Text className="teamCard">
                                        Senior Gr. Designer
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col sm={12} md={6} lg={3}>
                            <Card  >
                                <Card.Img variant="top" src={TestImg} />
                                <Card.Body>
                                    <Card.Title>Saurov Roy</Card.Title>
                                    <Card.Text className="teamCard">
                                        Executive Gr. Designer
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>

                <br />
                <Container>
                    <Row>
                        <Col sm={12} md={6} lg={3}>
                            <Card  >
                                <Card.Img variant="top" src={TestImg} />
                                <Card.Body>
                                    <Card.Title>Md. Abdur Razzak</Card.Title>
                                    <Card.Text className="teamCard">
                                        Executive Gr. Designer
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col sm={12} md={6} lg={3}>
                            <Card  >
                                <Card.Img variant="top" src={TestImg} />
                                <Card.Body>
                                    <Card.Title>Joyanta Kumar Roy</Card.Title>
                                    <Card.Text className="teamCard">
                                        Executive Gr. Designer
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col sm={12} md={6} lg={3}>
                            <Card  >
                                <Card.Img variant="top" src={TestImg} />
                                <Card.Body>
                                    <Card.Title>Md. Shariful Islam</Card.Title>
                                    <Card.Text className="teamCard">
                                        Senior Gr. Designer
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col sm={12} md={6} lg={3}>
                            <Card  >
                                <Card.Img variant="top" src={Pollob} />
                                <Card.Body>
                                    <Card.Title>Pollob Roy</Card.Title>
                                    <Card.Text className="teamCard">
                                        Executive Gr. Designer
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>


                <br />
                <Container>
                    <Row>
                        <Col sm={12} md={6} lg={3}>
                            <Card  >
                                <Card.Img variant="top" src={TestImg} />
                                <Card.Body>
                                    <Card.Title>Dilip Roy</Card.Title>
                                    <Card.Text className="teamCard">
                                        Executive Gr. Designer
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col sm={12} md={6} lg={3}>
                            <Card  >
                                <Card.Img variant="top" src={TestImg} />
                                <Card.Body>
                                    <Card.Title>Ramprosad Roy</Card.Title>
                                    <Card.Text className="teamCard">
                                        Junior Gr. Designer
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col sm={12} md={6} lg={3}>
                            <Card  >
                                <Card.Img variant="top" src={TestImg} />
                                <Card.Body>
                                    <Card.Title>Konok Roy</Card.Title>
                                    <Card.Text className="teamCard">
                                        Senior Gr. Designer
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col sm={12} md={6} lg={3}>
                            <Card  >
                                <Card.Img variant="top" src={TestImg} />
                                <Card.Body>
                                    <Card.Title>Mashiur Rahman</Card.Title>
                                    <Card.Text className="teamCard">
                                        Executive Gr. Designer
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>


                <br />
                <Container>
                    <Row>
                        <Col sm={12} md={6} lg={3}>
                            <Card  >
                                <Card.Img variant="top" src={TestImg} />
                                <Card.Body>
                                    <Card.Title>Khokon Chandro Roy</Card.Title>
                                    <Card.Text className="teamCard">
                                        Executive Gr. Designer
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col sm={12} md={6} lg={3}>
                            <Card  >
                                <Card.Img variant="top" src={TestImg} />
                                <Card.Body>
                                    <Card.Title>Md. Jannatul Ferdoush</Card.Title>
                                    <Card.Text className="teamCard">
                                        Executive Gr. Designer
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col sm={12} md={6} lg={3}>
                            <Card  >
                                <Card.Img variant="top" src={TestImg} />
                                <Card.Body>
                                    <Card.Title>Jibon Chandra Roy</Card.Title>
                                    <Card.Text className="teamCard">
                                        Executive Gr. Designer
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col sm={12} md={6} lg={3}>
                            <Card  >
                                <Card.Img variant="top" src={TestImg} />
                                <Card.Body>
                                    <Card.Title>Arun Ray</Card.Title>
                                    <Card.Text className="teamCard">
                                        Executive Gr. Designer
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </Fragment>
        );
    }
}

export default TheTeam;