import axios from 'axios';

class RestClient {
    // this is used to get message.
    static GetRequest=(getUrl)=>{
        return axios.get(getUrl).then(response=>{
            return response.data
        }).catch(error=>{
            return null;
        });
    }

    // this is used to post message.
    static PostRequest=(postURI, postJson)=>{
        let config={
            headers: {'Content-Type': 'application/x-www-form-urlencoded',}
        }


        return axios.post(postURI,postJson,config).then(response=>{
            return response.data
        }).catch(error=>{
            return null;
        });
    }
}
export default RestClient;