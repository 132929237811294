class AppUrl {

    static BaseUrl='https://cpoexpertsbd.com';


    static hometoptitle=this.BaseUrl+'/hometoptitle';
    static techdesc=this.BaseUrl+'/techdesc';
    static totalproject=this.BaseUrl+'/totalproject';
    static videohome=this.BaseUrl+'/videohome';
    static projectdetails=this.BaseUrl+'/projectdetails/';
    static projectall=this.BaseUrl+'/projectall';
    static project3=this.BaseUrl+'/project3';
    static services=this.BaseUrl+'/services';
    static information=this.BaseUrl+'/information';
    static footer=this.BaseUrl+'/footer';
    static coursedetails=this.BaseUrl+'/coursedetails';
    static courseall=this.BaseUrl+'/courseall';
    static coursehome=this.BaseUrl+'/coursehome';
    static ContactSend=this.BaseUrl+'/ContactSend';
    static clientreview=this.BaseUrl+'/clientreview';
    static chartdata=this.BaseUrl+'/chartdata';

}

export default AppUrl;