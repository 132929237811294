import React, {Component, Fragment} from 'react';
import {Route, Switch} from "react-router-dom";
import HomePage from "../pages/HomePage";
import ServicePage from "../pages/ServicePage";
import CoursesPage from "../pages/CoursesPage";
import AboutPage from "../pages/AboutPage";
import AboutUsPage from "../pages/AboutUsPage";
import PortfolioPage from "../pages/PortfolioPage";
import ContactPage from "../pages/ContactPage";
import RefundPage from "../pages/RefundPage";
import TermsPage from "../pages/TermsPage";
import PrivacyPage from "../pages/PrivacyPage";
import ProjectDetailsPage from "../pages/ProjectDetailsPage";
import CourseDetailsPage from "../pages/CourseDetailsPage";
import CSRPage from "../pages/CSRPage";
import TheTeamPage from "../pages/TheTeamPage";


class AppRoute extends Component {

    render() {
        return (
            <Fragment>
                <Switch>
                    <Route exact path="/" component={HomePage}/>
                    <Route exact path="/service" component={ServicePage}/>
                    <Route exact path="/course" component={CoursesPage}/>
                    <Route exact path="/about" component={AboutPage}/>
                    <Route exact path="/aboutus" component={AboutUsPage}/>
                    <Route exact path="/portfolio" component={PortfolioPage}/>
                    <Route exact path="/contact" component={ContactPage}/>
                    <Route exact path="/refund" component={RefundPage}/>
                    <Route exact path="/termsofuse" component={TermsPage}/>
                    <Route exact path="/privacy" component={PrivacyPage}/>
                    <Route exact path="/projectdetails/:projectID" component={ProjectDetailsPage} />
                    <Route exact path="/coursedetails" component={CourseDetailsPage} />
                    <Route exact path="/csr" component={CSRPage} />
                    <Route exact path="/team" component={TheTeamPage} />
                </Switch>
            </Fragment>
        );
    }
}

export default AppRoute;