import React, {Component, Fragment} from 'react';
import {Col, Container, Row} from "react-bootstrap";
import nishapicture from "../../asset/image/nisha.jpg";
import {Link} from "react-router-dom";

class AllCourses extends Component {
    render() {
        return (
            <Fragment>
                <Fragment>
                    <Container className="mt-5">
                        <Row>
                            <Col lg={6} md={12} sm={12}>
                                <Row>
                                    <Col lg={6} md={6} sm={12}>
                                        <img className="courseImg" src="https://cdn.pixabay.com/photo/2016/01/19/17/53/writing-1149962_960_720.jpg" />

                                    </Col>
                                    <Col lg={6} md={6} sm={12}>
                                        <h5 className="courseTitle text-justify">Web Development</h5>
                                        <p className="courseDes text-justify"> adsfad adfadf adfasdf adfasdfas adfadfad adfasdf adfdf.</p>
                                        <Link className="courseDetails text-justify" to="/coursedetails">Click Me</Link>
                                    </Col>
                                </Row>
                            </Col>

                            <Col lg={6} md={12} sm={12}>
                                <Row>
                                    <Col lg={6} md={6} sm={12}>
                                        <img className="courseImg" src="https://cdn.pixabay.com/photo/2016/01/19/17/53/writing-1149962_960_720.jpg" />

                                    </Col>
                                    <Col lg={6} md={6} sm={12}>
                                        <h5 className="courseTitle text-justify">Web Development</h5>
                                        <p className="courseDes text-justify"> adsfad adfadf adfasdf adfasdfas adfadfad adfasdf adfdf.</p>
                                        <Link className="courseDetails text-justify" to="/coursedetails">Click Me</Link>
                                    </Col>
                                </Row>
                            </Col>

                            <Col lg={6} md={12} sm={12}>
                                <Row>
                                    <Col lg={6} md={6} sm={12}>
                                        <img className="courseImg" src="https://cdn.pixabay.com/photo/2016/01/19/17/53/writing-1149962_960_720.jpg" />

                                    </Col>
                                    <Col lg={6} md={6} sm={12}>
                                        <h5 className="courseTitle text-justify">Web Development</h5>
                                        <p className="courseDes text-justify"> adsfad adfadf adfasdf adfasdfas adfasdf adfasdf adfadfad adfasdf adfdf.</p>
                                        <Link className="courseDetails text-justify" to="/coursedetails">Click Me</Link>
                                    </Col>
                                </Row>
                            </Col>

                            <Col lg={6} md={12} sm={12}>
                                <Row>
                                    <Col lg={6} md={6} sm={12}>
                                        <img className="courseImg" src="https://cdn.pixabay.com/photo/2016/01/19/17/53/writing-1149962_960_720.jpg" />
                                    </Col>
                                    <Col lg={6} md={6} sm={12}>
                                        <h5 className="courseTitle text-justify">Web Development</h5>
                                        <p className="courseDes text-justify"> adsfad adfadf adfasdf adfasdfas adfasdf adfasdf
                                            adfadfad adfasdf adfdf.</p>
                                        <Link className="courseDetails text-justify" to="/coursedetails">Click Me</Link>
                                    </Col>
                                </Row>
                            </Col>
                            <Col lg={6} md={12} sm={12}>
                                <Row>
                                    <Col lg={6} md={6} sm={12}>
                                        <img className="courseImg" src="https://cdn.pixabay.com/photo/2016/01/19/17/53/writing-1149962_960_720.jpg" />
                                    </Col>
                                    <Col lg={6} md={6} sm={12}>
                                        <h5 className="courseTitle text-justify">Web Development</h5>
                                        <p className="courseDes text-justify"> adsfad adfadf adfasdf adfasdfas adfasdf adfasdf
                                            adfadfad adfasdf adfdf.</p>
                                        <Link className="courseDetails text-justify" to="/coursedetails">Click Me</Link>
                                    </Col>
                                </Row>
                            </Col>

                            <Col lg={6} md={12} sm={12}>
                                <Row>
                                    <Col lg={6} md={6} sm={12}>
                                        <img className="courseImg" src="https://cdn.pixabay.com/photo/2016/01/19/17/53/writing-1149962_960_720.jpg" />
                                    </Col>
                                    <Col lg={6} md={6} sm={12}>
                                        <h5 className="courseTitle text-justify">Web Development</h5>
                                        <p className="courseDes text-justify"> adsfad adfadf adfasdf adfasdfas adfasdf adfasdf
                                            adfadfad adfasdf adfdf.</p>
                                        <Link className="courseDetails text-justify" to="/coursedetails">Click Me</Link>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Container>
                </Fragment>
            </Fragment>
        );
    }
}

export default AllCourses;