import React, {Component, Fragment} from 'react';
import {Button, Card, Col, Container, Row} from "react-bootstrap";
import csrImage from '../../asset/image/CSR-CPO-Experts.jpg';
import BloodDonation from '../../asset/image/Blood-Donation-CPO.jpg';
import RestClient from "../../RestAPI/RestClient";
import AppUrl from "../../RestAPI/AppUrl";
import ReactHtmlParser from "react-html-parser";

class CorporateSocialResponsibility extends Component {

    // constructor() {
    //     super();
    //     this.state={
    //         desc:""
    //     }
    // }
    //
    // componentDidMount() {
    //     RestClient.GetRequest(AppUrl.information).then(result=>{
    //         this.setState({desc:result[0]['about']});
    //     })
    // }

    render() {
        return (
            <Fragment>
                <Container className="mt-5 text-justify">
                    <Row>
                        <Col sm={12} md={12} lg={12}>
                            <p> We at CPO Experts BD believes Corporate Social Responsibility is an essential part of making the world a better planet of living.
                                In many ways, we can take responsibilities to change the global crisis and make the earth a better place for all of our communities.
                                If we all take our initial steps to make the world a better place from our every capability,
                                then it is entirely possible to make the world more beautiful and happy livelihood.
                            </p>
                            {/*<h1> Education Sponsorship </h1>*/}
                            <hr />

                            <Container className="text-center">
                                <h1 className="csrSubTitle"> Scholarship Program </h1>
                                <Row>
                                    <Col lg={6} md={6} sm={12}>
                                        <div className="serviceCard text-center">
                                            <img src={csrImage} />
                                        </div>
                                    </Col>

                                    <Col lg={6} md={6} sm={12}>
                                        <div className="serviceDescription serviceCard text-justify">
                                            <p>
                                                Better education and good health are essential for society.
                                                We think these two factors need our attention as giving back responsibility to the community.
                                                Many students cannot go to school due to poverty and work as day labor.
                                                We provide scholarships to students who are talented or want to study but have a financial crisis.
                                                <br />
                                                Therefore, we at CPO Experts are contributing some percentage of our profit to continue studies.
                                                Eventually, they will be the future of their family, society, and the world.
                                            </p>
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                            {/*<hr />*/}

                            <Container className="text-center">
                                <h1 className="csrSubTitle"> Blood Donation Camp </h1>
                                <Row>


                                    <Col lg={6} md={6} sm={12}>
                                        <div className="serviceDescription serviceCard text-justify">
                                            <p> As a social responsibility, we also undertake campaigns like donating blood.
                                                There is no substitute for blood when a patient needs it to survive.
                                                Some of our employees are permanent members of the Quantum Blood Bank organization.
                                                So, whenever a possibility comes, we step forward to donate blood.
                                                We also take care of employee health, giving medical benefits.
                                            </p>
                                        </div>
                                    </Col>

                                    <Col lg={6} md={6} sm={12}>
                                        <div className="serviceCard text-center">
                                            <img src={BloodDonation} />
                                        </div>
                                    </Col>
                                </Row>
                            </Container>

                            <h1 className="csrSubTitle text-center"> Our Endeavor + Your Contribution = Better world</h1>
                            <p> If you want to see the world a better place, willing to help others but don’t find a place where exactly it is needed.
                                You get the opportunities to participate with our CSR program.
                                Just let us know how you want.
                                Your contribution will help us to expand the campaign of Corporate Social Responsibility.
                            </p>
                        </Col>
                    </Row>
                </Container>
            </Fragment>
        );
    }
}

export default CorporateSocialResponsibility;