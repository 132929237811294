import React, {Component, Fragment} from 'react';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import {Col, Container, Row} from "react-bootstrap";
import nishapicture from "../../asset/image/nisha.jpg";
import RestClient from "../../RestAPI/RestClient";
import AppUrl from "../../RestAPI/AppUrl";
import {Link} from "react-router-dom";

class ClientReview extends Component {

    constructor() {
        super();
        this.state={
            myData:[]
        }
    }

    componentDidMount() {
        RestClient.GetRequest(AppUrl.clientreview).then(result=>{
            this.setState({myData:result});
        })
    }



    render() {

        var settings = {
            // autoplaySpeed: 3000,
            // autoplay: true,
            dots: true,
            infinite: true,
            speed: 3000,
            slidesToShow: 1,
            slidesToScroll: 3,
            initialSlide: 0,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,
                        infinite: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                        initialSlide: 2
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        };




        const myList=this.state.myData;
        const myView=myList.map(myList=>{
            return  <div>
                <Row className="text-center">
                    <Col>
                        <img className="circleImg" src={myList.client_img}/>
                        <h1 className="serviceName">{myList.client_title}</h1>
                        <p className="serviceDescription">{myList.client_description}</p>
                    </Col>
                </Row>
            </div>
        })


        return (
            <Fragment>
                <Container className="text-center">
                    <h1 className="serviceMainTitle">What Client says</h1>
                    <Slider {...settings}>
                        {myView}
                    </Slider>

                </Container>
            </Fragment>
        );
    }
}

export default ClientReview;