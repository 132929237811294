import React, {Component, Fragment} from 'react';
import {Col, Container, Row} from "react-bootstrap";
import nishapicture from "../../asset/image/nisha.jpg";
import {Link} from "react-router-dom";
import RestClient from "../../RestAPI/RestClient";
import AppUrl from "../../RestAPI/AppUrl";


class Courses extends Component {

    constructor() {
        super();
        this.state={
            myData:[]
        }
    }

    componentDidMount() {
        RestClient.GetRequest(AppUrl.coursehome).then(result=>{
            this.setState({myData:result});
        })
    }


    render() {

        const myList=this.state.myData;
        const myView=myList.map(myList=>{
            return  <Col lg={6} md={12} sm={12}>
                <Row>
                    <Col lg={6} md={6} sm={12}>
                        <img className="courseImg" src={myList.small_img} />
                    </Col>
                    <Col lg={6} md={6} sm={12}>
                        <h5 className="courseTitle text-justify">{myList.short_title}</h5>
                        <p className="courseDes text-justify"> {myList.short_des}</p>
                        <Link className="courseDetails text-justify" to="/coursedetails">Course Details</Link>
                    </Col>
                </Row>
            </Col>
        })



        return (
            <Fragment>
                <Container>
                    <h1 className="serviceMainTitle text-center">Our Courses</h1>
                    <Row>
                        {myView}
                    </Row>
                </Container>

            </Fragment>
        );
    }
}

export default Courses;