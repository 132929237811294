import React, {Component, Fragment} from 'react';
import {Button, Col, Container, Row} from "react-bootstrap";
import graphicsLogo from '../../asset/image/graphics.svg'
import webLogo from  '../../asset/image/web.svg'
import mobileLogo from '../../asset/image/mobile.svg'
import RestClient from "../../RestAPI/RestClient";
import AppUrl from "../../RestAPI/AppUrl";
import {Link} from "react-router-dom";

class Services extends Component {

    // constructor() {
    //     super();
    //     this.state={
    //         myData:[]
    //     }
    // }
    //
    // componentDidMount() {
    //     RestClient.GetRequest(AppUrl.services).then(result=>{
    //         this.setState({myData:result});
    //     }).catch(error=>{
    //         this.setState({title:"???", subtitle:"???"});
    //     });
    // }


    render() {

        //
        // const myList=this.state.myData;
        // const myView=myList.map(myList=>{
        //     return <Col lg={6} md={6} sm={12}>
        //         <div className="serviceCard text-center">
        //             <img src={myList.service_logo} />
        //             <h2 className="serviceName">{myList.service_name}</h2>
        //             <p className="serviceDescription">{myList.service_description}</p>
        //             <Button variant="primary"><a className="linkStyle" href={"https://www.clippingpathoutsource.com"}>Visit Website</a></Button>
        //         </div>
        //     </Col>
        //
        // })


        return (
            <Fragment>
                <Container className="text-center">
                    <h1 className="serviceMainTitle"> Brands of CPO Experts</h1>
                    <Row>
                        <Col lg={6} md={6} sm={12}>
                            <div className="serviceCard text-center">
                                <img src={graphicsLogo} />
                                <h2 className="serviceName">Clipping Path Outsource</h2>
                                <p className="serviceDescription">Image editing, e-commerce product optimisation etc.
                                    services are available from Clipping Path Outsource, a branch of CPO Experts Ltd.</p>
                                <Button variant="primary"><a className="linkStyle" href={"https://www.clippingpathoutsource.com"}>Visit Website</a></Button>
                            </div>
                        </Col>

                        <Col lg={6} md={6} sm={12}>
                            <div className="serviceCard text-center">
                                <img src={webLogo} />
                                <h2 className="serviceName">We Dev Solution</h2>
                                <p className="serviceDescription">Web application, mobile apps, software development & IT Services.
                                    WeDevSolution is a sister company of CPO Experts Ltd.</p>
                                <Button variant="primary"><a className="linkStyle" href={"https://www.clippingpathoutsource.com"}>Visit Website</a></Button>
                            </div>
                        </Col>


                        {/*{myView}*/}
                    </Row>
                </Container>
            </Fragment>
        );
    }
}

export default Services;